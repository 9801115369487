/* Reset default margin and padding */


p,li,ol{
  font-size: 18px;
  line-height: 27px !important;
  padding: 0;
  margin: 0;
}

li {
  margin-left: 1.4rem;  
  font-size: 18px !important;
  line-height: 27px !important;
}

button {
  background-color: #9D5EFB;
  border: 2px #C59EFF solid;
  font-size: 23px;
  font-weight: 800;
  line-height: 33px;
  word-wrap: break-word
}

h1 {
  font-size: 42px;
  padding: 0;
  margin: 0;
}

h2 {
  font-size: 35px;
  line-height: 50px;
  padding: 0;
  margin: 0;
}

h3 {
  font-size: 32px;
  padding: 0;
  margin: 0;
}


html {
  overflow-y: auto;
}

body{
  overflow-y: scroll;
}

/* Default light mode styles */
/* body {
  background-color: #ffffff;
  color: #000000;
} */

body {
  background-color: #1e1c21;
  color: #ffffff;
}

/* Dark mode styles */
@media (prefers-color-scheme: light) {
  body {
      background-color: #1e1c21;
      color: #ffffff;
  }
}


html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

div {
  margin: 0;
  padding: 0
}

h1,h2,h3 {
  margin:0 0 1rem 0;
  padding:0
}

.vocational-quiz-wrapper {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100%;
  padding-bottom: auto;
  
}
.vocational-quiz-wrapper h1{
  font-weight: 800;
  line-height: 55.84px;
  word-wrap: break-word
}

@media (max-width: 600px) {
  
  body {
    padding-bottom: 1rem;
  }
}