.form-container {
  padding: 40px;
  border-radius: 8px;
  width: 50%;
  margin: auto;
  margin-top: 2rem
}

.form-container h3 {
  font-size: 20px;
}

.quase-la{
  color: #FDD549 !important;
  margin-bottom: 1rem;
}

.form-header {
  text-align: center;
  margin-bottom: 32px;
  color: #dcdcdc;
  text-align: left;
}

.input-group input {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: none;
  background-color: #3F3F3F;
  color: #ccc;
  font-size: 20px;
  line-height: 30px;
}

.submit-button {
  /* color:white; */
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  background-color: #9D5EFB;
  cursor: pointer;
  border-radius: 12px; 
  border: '2px #C59EFF solid';
  font-weight: 400;
  color:white;
}

.submit-button:hover {
  opacity: 0.9;
}
.input-group {
    margin-bottom: 15px;
    border-radius: 10px;
    border: '2px #656565 solid'
}

.phone-input-group {
    display: flex;
    align-items: center;
}

.area-code-select,
.phone-number-input {
    height: 40px; /* Match height with other inputs */
    margin-right: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.phone-number-input {
    flex-grow: 1; /* Allow phone number input to take remaining space */
}

  
  /* Responsive styling */
  @media (max-width: 768px) {
    .form-container {
      width: 80%;
    }
  }
  
  input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    background-color: #3F3F3F !important; /* Your desired background color */
    color: #fff !important; /* Choose your desired text color */
}

/* Firefox */
input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
textarea:-moz-autofill,
textarea:-moz-autofill:hover,
textarea:-moz-autofill:focus,
select:-moz-autofill,
select:-moz-autofill:hover,
select:-moz-autofill:focus {
    background-color: #3F3F3F !important; /* Your desired background color */
    color: #fff !important; /* Choose your desired text color */
}